@import './fonts/fonts.css';

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --mainOrange: #f7941d;
  --mainOrangeHover: #de851a;
  --blackHover: #1a1a1a;
  --lightGray: #f6f4f9;
  --darkGray: #726d7b;
  --cardGrayBg: #d9d9d9;
  --dividerClr: rgba(0, 0, 0, 0.12);
  --activeDashboardItem: #7c4a0f;
  --errorClr: #ff3333;
  --successClr: #4caf50;
  --enFont: 'Leelawadee';
  --arFont: 'DIN NEXTTM ARABIC';
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-size: 1.6rem;
  font-family: var(--enFont);
  background: var(--lightGray);
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  display: inline-block;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}

::selection {
  color: white;
  background: var(--mainOrange);
}
